<template>
  <div class="h-svh flex flex-col pt-20">
    <Header />

    <div class="mx-auto container">
      <Breadcrumbs />
    </div>

    <main class="mx-auto container flex-1 pt-4 pb-14 lg:py-6">
      <slot />
    </main>

    <Footer />
  </div>
</template>
